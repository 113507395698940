import { getFontSize } from "@tamagui/font-size";
import { getFontSized } from "@tamagui/get-font-sized";
import { getSize, getSpace } from "@tamagui/get-token";
import { withStaticProperties } from "@tamagui/helpers";
import { useGetThemedIcon } from "@tamagui/helpers-tamagui";
import { ThemeableStack, YStack } from "@tamagui/stacks";
import { SizableText, wrapChildrenInText } from "@tamagui/text";
import {
  Spacer,
  getTokens,
  getVariableValue,
  styled,
  useProps
} from "@tamagui/web";
import { Fragment, jsx, jsxs } from "react/jsx-runtime";
const NAME = "ListItem", ListItemFrame = styled(ThemeableStack, {
  name: NAME,
  tag: "li",
  variants: {
    unstyled: {
      false: {
        size: "$true",
        alignItems: "center",
        justifyContent: "space-between",
        flexWrap: "nowrap",
        width: "100%",
        borderColor: "$borderColor",
        maxWidth: "100%",
        overflow: "hidden",
        flexDirection: "row",
        backgroundColor: "$background"
      }
    },
    size: {
      "...size": (val, { tokens }) => ({
        minHeight: tokens.size[val],
        paddingHorizontal: tokens.space[val],
        paddingVertical: getSpace(tokens.space[val], {
          shift: -4
        })
      })
    },
    active: {
      true: {
        hoverStyle: {
          backgroundColor: "$background"
        }
      }
    },
    disabled: {
      true: {
        opacity: 0.5,
        // TODO breaking types
        pointerEvents: "none"
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), ListItemText = styled(SizableText, {
  name: "ListItemText",
  variants: {
    unstyled: {
      false: {
        color: "$color",
        size: "$true",
        flexGrow: 1,
        flexShrink: 1,
        ellipse: !0,
        cursor: "default"
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), ListItemSubtitle = styled(ListItemText, {
  name: "ListItemSubtitle",
  variants: {
    unstyled: {
      false: {
        opacity: 0.6,
        maxWidth: "100%",
        color: "$color"
      }
    },
    size: {
      "...size": (val, extras) => {
        const oneSmaller = getSize(val, {
          shift: -1,
          excludeHalfSteps: !0
        });
        return getFontSized(oneSmaller.key, extras);
      }
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), ListItemTitle = styled(ListItemText, {
  name: "ListItemTitle"
}), useListItem = (propsIn, {
  Text = ListItemText,
  Subtitle = ListItemSubtitle,
  Title = ListItemTitle
} = { Text: ListItemText, Subtitle: ListItemSubtitle, Title: ListItemTitle }) => {
  const props = useProps(propsIn), {
    children,
    icon,
    iconAfter,
    noTextWrap,
    theme: themeName,
    space,
    spaceFlex,
    scaleIcon = 1,
    scaleSpace = 1,
    unstyled = !1,
    subTitle,
    title,
    // text props
    color,
    fontWeight,
    fontSize,
    fontFamily,
    letterSpacing,
    textAlign,
    ellipse,
    ...rest
  } = props, textProps = {
    color,
    fontWeight,
    fontSize,
    fontFamily,
    letterSpacing,
    textAlign,
    ellipse,
    children
  }, size = props.size || "$true", iconSize = getFontSize(size) * scaleIcon, getThemedIcon = useGetThemedIcon({ size: iconSize, color }), [themedIcon, themedIconAfter] = [icon, iconAfter].map(getThemedIcon), spaceSize = getVariableValue(getTokens().space[props.space] ?? iconSize) * scaleSpace, contents = wrapChildrenInText(Text, textProps);
  return {
    props: {
      ...rest,
      children: /* @__PURE__ */ jsxs(Fragment, { children: [
        themedIcon ? /* @__PURE__ */ jsxs(Fragment, { children: [
          themedIcon,
          /* @__PURE__ */ jsx(Spacer, { size: spaceSize })
        ] }) : null,
        title || subTitle ? /* @__PURE__ */ jsxs(YStack, { flex: 1, children: [
          noTextWrap === "all" ? title : /* @__PURE__ */ jsx(Title, { size, children: title }),
          subTitle ? /* @__PURE__ */ jsx(Fragment, { children: typeof subTitle == "string" && noTextWrap !== "all" ? (
            // TODO can use theme but we need to standardize to alt themes
            // or standardize on subtle colors in themes
            /* @__PURE__ */ jsx(Subtitle, { unstyled, size, children: subTitle })
          ) : subTitle }) : null,
          contents
        ] }) : contents,
        themedIconAfter ? /* @__PURE__ */ jsxs(Fragment, { children: [
          /* @__PURE__ */ jsx(Spacer, { size: spaceSize }),
          themedIconAfter
        ] }) : null
      ] })
    }
  };
}, ListItemComponent = ListItemFrame.styleable(
  function(props, ref) {
    const { props: listItemProps } = useListItem(props);
    return /* @__PURE__ */ jsx(ListItemFrame, { ref, ...listItemProps });
  }
), ListItem2 = withStaticProperties(ListItemComponent, {
  Text: ListItemText,
  Subtitle: ListItemSubtitle
});
export {
  ListItem2 as ListItem,
  ListItemFrame,
  ListItemSubtitle,
  ListItemText,
  ListItemTitle,
  useListItem
};
