import { useComposedRefs } from "@tamagui/compose-refs";
import { useEvent } from "@tamagui/use-event";
import * as React from "react";
import { Fragment, jsx } from "react/jsx-runtime";
const AUTOFOCUS_ON_MOUNT = "focusScope.autoFocusOnMount", AUTOFOCUS_ON_UNMOUNT = "focusScope.autoFocusOnUnmount", EVENT_OPTIONS = { bubbles: !1, cancelable: !0 }, FocusScope = React.forwardRef(
  function(props, forwardedRef) {
    const childProps = useFocusScope(props, forwardedRef);
    return typeof props.children == "function" ? /* @__PURE__ */ jsx(Fragment, { children: props.children(childProps) }) : React.cloneElement(React.Children.only(props.children), childProps);
  }
);
function useFocusScope(props, forwardedRef) {
  const {
    loop = !1,
    enabled = !0,
    trapped = !1,
    onMountAutoFocus: onMountAutoFocusProp,
    onUnmountAutoFocus: onUnmountAutoFocusProp,
    forceUnmount,
    children,
    ...scopeProps
  } = props, [container, setContainer] = React.useState(null), onMountAutoFocus = useEvent(onMountAutoFocusProp), onUnmountAutoFocus = useEvent(onUnmountAutoFocusProp), lastFocusedElementRef = React.useRef(null), composedRefs = useComposedRefs(forwardedRef, (node) => setContainer(node)), focusScope = React.useRef({
    paused: !1,
    pause() {
      this.paused = !0;
    },
    resume() {
      this.paused = !1;
    }
  }).current;
  React.useEffect(() => {
    if (!enabled || !trapped)
      return;
    function handleFocusIn(event) {
      if (focusScope.paused || !container)
        return;
      const target = event.target;
      container.contains(target) ? lastFocusedElementRef.current = target : focus(lastFocusedElementRef.current, { select: !0 });
    }
    function handleFocusOut(event) {
      focusScope.paused || !container || container.contains(event.relatedTarget) || focus(lastFocusedElementRef.current, { select: !0 });
    }
    return document.addEventListener("focusin", handleFocusIn), document.addEventListener("focusout", handleFocusOut), () => {
      document.removeEventListener("focusin", handleFocusIn), document.removeEventListener("focusout", handleFocusOut);
    };
  }, [trapped, forceUnmount, container, focusScope.paused]), React.useEffect(() => {
    if (!enabled || !container || forceUnmount)
      return;
    focusScopesStack.add(focusScope);
    const previouslyFocusedElement = document.activeElement;
    if (!container.contains(previouslyFocusedElement)) {
      const mountEvent = new CustomEvent(AUTOFOCUS_ON_MOUNT, EVENT_OPTIONS);
      if (container.addEventListener(AUTOFOCUS_ON_MOUNT, onMountAutoFocus), container.dispatchEvent(mountEvent), !mountEvent.defaultPrevented) {
        const candidates = removeLinks(getTabbableCandidates(container));
        focusFirst(candidates, { select: !0 }), document.activeElement === previouslyFocusedElement && focus(container);
      }
    }
    return () => {
      container.removeEventListener(AUTOFOCUS_ON_MOUNT, onMountAutoFocus);
      const unmountEvent = new CustomEvent(AUTOFOCUS_ON_UNMOUNT, EVENT_OPTIONS);
      container.addEventListener(AUTOFOCUS_ON_UNMOUNT, onUnmountAutoFocus), container.dispatchEvent(unmountEvent), unmountEvent.defaultPrevented || focus(previouslyFocusedElement ?? document.body, { select: !0 }), container.removeEventListener(AUTOFOCUS_ON_UNMOUNT, onUnmountAutoFocus), focusScopesStack.remove(focusScope);
    };
  }, [enabled, container, forceUnmount, onMountAutoFocus, onUnmountAutoFocus, focusScope]);
  const handleKeyDown = React.useCallback(
    (event) => {
      if (!trapped || !loop || focusScope.paused)
        return;
      const isTabKey = event.key === "Tab" && !event.altKey && !event.ctrlKey && !event.metaKey, focusedElement = document.activeElement;
      if (isTabKey && focusedElement) {
        const container2 = event.currentTarget, [first, last] = getTabbableEdges(container2);
        first && last ? !event.shiftKey && focusedElement === last ? (event.preventDefault(), loop && focus(first, { select: !0 })) : event.shiftKey && focusedElement === first && (event.preventDefault(), loop && focus(last, { select: !0 })) : focusedElement === container2 && event.preventDefault();
      }
    },
    [loop, trapped, focusScope.paused]
  );
  return {
    tabIndex: -1,
    ...scopeProps,
    ref: composedRefs,
    onKeyDown: handleKeyDown
  };
}
function focusFirst(candidates, { select = !1 } = {}) {
  const previouslyFocusedElement = document.activeElement;
  for (const candidate of candidates)
    if (focus(candidate, { select }), document.activeElement !== previouslyFocusedElement)
      return;
}
function getTabbableEdges(container) {
  const candidates = getTabbableCandidates(container), first = findVisible(candidates, container), last = findVisible(candidates.reverse(), container);
  return [first, last];
}
function getTabbableCandidates(container) {
  const nodes = [], walker = document.createTreeWalker(container, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (node) => {
      const isHiddenInput = node.tagName === "INPUT" && node.type === "hidden";
      return node.disabled || node.hidden || isHiddenInput ? NodeFilter.FILTER_SKIP : node.tabIndex >= 0 ? NodeFilter.FILTER_ACCEPT : NodeFilter.FILTER_SKIP;
    }
  });
  for (; walker.nextNode(); )
    nodes.push(walker.currentNode);
  return nodes;
}
function findVisible(elements, container) {
  for (const element of elements)
    if (!isHidden(element, { upTo: container }))
      return element;
}
function isHidden(node, { upTo }) {
  if (getComputedStyle(node).visibility === "hidden")
    return !0;
  for (; node; ) {
    if (upTo !== void 0 && node === upTo)
      return !1;
    if (getComputedStyle(node).display === "none")
      return !0;
    node = node.parentElement;
  }
  return !1;
}
function isSelectableInput(element) {
  return element instanceof HTMLInputElement && "select" in element;
}
function focus(element, { select = !1 } = {}) {
  if (element?.focus) {
    const previouslyFocusedElement = document.activeElement;
    element.focus({ preventScroll: !0 }), element !== previouslyFocusedElement && isSelectableInput(element) && select && element.select();
  }
}
const focusScopesStack = createFocusScopesStack();
function createFocusScopesStack() {
  let stack = [];
  return {
    add(focusScope) {
      const activeFocusScope = stack[0];
      focusScope !== activeFocusScope && activeFocusScope?.pause(), stack = arrayRemove(stack, focusScope), stack.unshift(focusScope);
    },
    remove(focusScope) {
      stack = arrayRemove(stack, focusScope), stack[0]?.resume();
    }
  };
}
function arrayRemove(array, item) {
  const updatedArray = [...array], index = updatedArray.indexOf(item);
  return index !== -1 && updatedArray.splice(index, 1), updatedArray;
}
function removeLinks(items) {
  return items.filter((item) => item.tagName !== "A");
}
export {
  FocusScope,
  useFocusScope
};
