import { useCallback, useEffect, useRef, useState } from 'react';
export function useForceUpdate() {
  var _a = useState([]),
      forceUpdate = _a[1];

  var mountState = useRef({
    mounted: false,
    pending: false
  });
  useEffect(function () {
    mountState.current.mounted = true;

    if (mountState.current.pending) {
      mountState.current.pending = false;
      forceUpdate([]);
    }

    return function () {
      mountState.current = {
        mounted: false,
        pending: false
      };
    };
  }, []);
  var update = useCallback(function () {
    if (mountState.current.mounted) {
      forceUpdate([]);
    } else {
      mountState.current.pending = true;
    }
  }, [forceUpdate]);
  return update;
}