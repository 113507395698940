import { Text, styled } from "@tamagui/core";
const EnsureFlexed = styled(Text, {
  opacity: 0,
  lineHeight: 0,
  height: 0,
  display: "flex",
  fontSize: 200,
  children: "wwwwwwwwwwwwwwwwwww",
  hoverStyle: {
    backgroundColor: "red"
  },
  pointerEvents: "none"
});
EnsureFlexed.isVisuallyHidden = !0;
export {
  EnsureFlexed
};
