import { isWeb } from "@tamagui/constants";
import { styled } from "@tamagui/core";
import { textAreaSizeVariant } from "../helpers/inputHelpers";
import { InputFrame, defaultStyles, useInputProps } from "./Input";
import { jsx } from "react/jsx-runtime";
const TextAreaFrame = styled(InputFrame, {
  name: "TextArea",
  multiline: !0,
  // this attribute fixes firefox newline issue
  whiteSpace: "pre-wrap",
  variants: {
    unstyled: {
      false: {
        height: "auto",
        ...defaultStyles
      }
    },
    size: {
      "...size": textAreaSizeVariant
    }
  },
  defaultVariants: {
    unstyled: process.env.TAMAGUI_HEADLESS === "1"
  }
}), TextArea = TextAreaFrame.styleable((propsIn, ref) => {
  const props = useInputProps(propsIn, ref), linesProp = {
    // web uses rows now, but native not caught up :/
    [isWeb ? "rows" : "numberOfLines"]: propsIn.unstyled ? void 0 : 4
  };
  return /* @__PURE__ */ jsx(TextAreaFrame, { ...linesProp, ...props });
});
export {
  TextArea,
  TextAreaFrame
};
