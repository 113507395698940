import { getTokenValue, getVariable, usePropsAndStyle } from "@tamagui/core";
import React from "react";
import { jsx } from "react/jsx-runtime";
React.keep;
function themed(Component, opts = {
  defaultThemeColor: process.env.DEFAULT_ICON_THEME_COLOR || "$color",
  defaultStrokeWidth: 2,
  fallbackColor: "#000"
}) {
  return (propsIn) => {
    const [props, style, theme] = usePropsAndStyle(propsIn, {
      resolveValues: "web"
      // iOS doesnt support dynamic values for SVG so only optimize on web
    }), defaultColor = style.color ?? opts.defaultThemeColor, color = getVariable(
      (defaultColor ? theme[defaultColor] : void 0) || style.color || (props.disableTheme ? null : theme.color) || opts.fallbackColor
    ), size = typeof props.size == "string" ? getTokenValue(props.size, "size") : props.size, strokeWidth = typeof props.strokeWidth == "string" ? getTokenValue(props.strokeWidth, "size") : props.strokeWidth ?? `${opts.defaultStrokeWidth}`;
    return /* @__PURE__ */ jsx(
      Component,
      {
        ...props,
        color,
        size,
        strokeWidth,
        style
      }
    );
  };
}
export {
  themed
};
