import { isServer } from "@tamagui/constants";
import {
  activeThemeManagers,
  ensureThemeVariable,
  getConfig,
  getThemeCSSRules,
  proxyThemeToParents,
  simpleHash,
  updateConfig
} from "@tamagui/web";
import { startTransition } from "react";
function mutateThemes({
  themes,
  batch,
  insertCSS = !0,
  ...props
}) {
  const allThemesProxied = {}, allThemesRaw = {};
  for (const { name, theme } of themes) {
    const res = _mutateTheme({
      ...props,
      name,
      theme,
      // we'll do one update at the end
      avoidUpdate: !0,
      // always add which also replaces but doesnt fail first time
      mutationType: "add"
    });
    res && (allThemesProxied[name] = res.theme, allThemesRaw[name] = res.themeRaw);
  }
  const cssRules = insertCSS ? insertThemeCSS(allThemesRaw, batch) : [];
  return startTransition(() => {
    for (const themeName in allThemesProxied) {
      const theme = allThemesProxied[themeName];
      updateThemeConfig(themeName, theme), notifyThemeManagersOfUpdate(themeName, theme);
    }
  }), {
    themes: allThemesProxied,
    themesRaw: allThemesRaw,
    cssRules
  };
}
function _mutateTheme(props) {
  if (isServer) {
    process.env.NODE_ENV === "development" && console.warn("Theme mutation is not supported on server side");
    return;
  }
  const config = getConfig(), { name: themeName, theme: themeIn, insertCSS, mutationType } = props;
  if (process.env.NODE_ENV === "development") {
    if (!config)
      throw new Error("No config");
    const theme2 = config.themes[props.name];
    if (mutationType !== "add" && !theme2)
      throw new Error(
        `${mutationType === "replace" ? "Replace" : "Update"} theme failed! Theme ${props.name} does not exist`
      );
  }
  const theme = {
    ...mutationType === "update" ? config.themes[themeName] ?? {} : {},
    ...themeIn
  };
  for (const key in theme)
    ensureThemeVariable(theme, key);
  const themeProxied = proxyThemeToParents(themeName, theme), response = {
    themeRaw: theme,
    theme: themeProxied,
    cssRules: []
  };
  return props.avoidUpdate || (insertCSS && (response.cssRules = insertThemeCSS({
    [themeName]: theme
  })), updateThemeConfig(themeName, themeProxied), notifyThemeManagersOfUpdate(themeName, themeProxied)), response;
}
function updateThemeConfig(themeName, theme) {
  const config = getConfig();
  config.themes[themeName] = theme, updateConfig("themes", config.themes);
}
function notifyThemeManagersOfUpdate(themeName, theme) {
  activeThemeManagers.forEach((manager) => {
    manager.state.name === themeName && manager.updateStateFromProps(
      {
        name: themeName,
        forceTheme: theme
      },
      !0
    );
  });
}
function insertThemeCSS(themes, batch = !1) {
  const config = getConfig();
  let cssRules = [];
  for (const themeName in themes) {
    const theme = themes[themeName], rules = getThemeCSSRules({
      config,
      themeName,
      names: [themeName],
      hasDarkLight: !0,
      theme
    });
    cssRules = [...cssRules, ...rules], batch || updateStyle(`t_theme_style_${themeName}`, rules);
  }
  if (batch) {
    const id = simpleHash(typeof batch == "string" ? batch : Object.keys(themes).join(""));
    updateStyle(`t_theme_style_${id}`, cssRules);
  }
  return cssRules;
}
function updateStyle(id, rules) {
  const existing = document.querySelector(`#${id}`), style = document.createElement("style");
  style.id = id, style.appendChild(document.createTextNode(rules.join(`
`))), document.head.appendChild(style), existing && existing.parentElement?.removeChild(existing);
}
export {
  _mutateTheme,
  mutateThemes
};
