import { styled } from "@tamagui/core";
import { YStack } from "@tamagui/stacks";
const Fieldset = styled(YStack, {
  name: "Fieldset",
  tag: "fieldset",
  variants: {
    horizontal: {
      true: {
        flexDirection: "row",
        alignItems: "center"
      }
    }
  }
});
export {
  Fieldset
};
