import { __internal } from 'relay-runtime';
import { isNetworkPolicy, isStorePolicy } from './Utils';
var fetchQuery = __internal.fetchQuery;
var DATA_RETENTION_TIMEOUT = 30 * 1000;
export function fetchResolver(_a) {
  var _b = _a.doRetain,
      doRetain = _b === void 0 ? true : _b,
      disposeTemporary = _a.disposeTemporary;
  var _refetchSubscription = null;
  var disposable = null;
  var releaseQueryTimeout;
  var isLoading = false;
  var query;
  var promise;
  var error = null;
  var env;

  var update = function (loading, e) {
    if (e === void 0) {
      e = null;
    }

    isLoading = loading;
    error = e;
  };

  var lookupInStore = function (environment, operation, fetchPolicy, renderPolicy) {
    if (isStorePolicy(fetchPolicy)) {
      var check = environment.check(operation);
      var queryStatus = check.status;
      var hasFullQuery = queryStatus === 'available';
      var canPartialRender = hasFullQuery || renderPolicy === 'partial' && queryStatus !== 'stale';

      if (canPartialRender) {
        return {
          snapshot: environment.lookup(operation.fragment),
          full: hasFullQuery
        };
      }
    }

    return {
      snapshot: null,
      full: false
    };
  };

  var dispose = function () {
    clearTemporaryRetain();
    disposable && disposable.dispose();
    disposeRequest();
    disposable = null;
    env = null;
    query = null;
  };

  var clearTemporaryRetain = function () {
    clearTimeout(releaseQueryTimeout);
    releaseQueryTimeout = null;
  };

  var temporaryRetain = function () {
    var localReleaseTemporaryRetain = function () {
      clearTemporaryRetain();
      dispose();
      disposeTemporary && disposeTemporary();
    };

    releaseQueryTimeout = setTimeout(localReleaseTemporaryRetain, DATA_RETENTION_TIMEOUT);
  };

  var disposeRequest = function () {
    _refetchSubscription && _refetchSubscription.unsubscribe();
    error = null;
    isLoading = false;
  };

  var fetch = function (environment, operation, fetchPolicy, onComplete, onNext, onResponse, renderPolicy) {
    if (fetchPolicy === void 0) {
      fetchPolicy = 'network-only';
    }

    if (onComplete === void 0) {
      onComplete = function (_e, _u) {
        return undefined;
      };
    }

    var fetchHasReturned = false;

    if (env != environment || query.request.identifier !== operation.request.identifier) {
      dispose();

      if (doRetain) {
        disposable = environment.retain(operation);
      }
    }

    env = environment;
    query = operation;
    disposeRequest();

    var _a = lookupInStore(environment, operation, fetchPolicy, renderPolicy),
        snapshot = _a.snapshot,
        full = _a.full;

    var isNetwork = isNetworkPolicy(fetchPolicy, full);

    if (snapshot != null) {
      var onlyStore = !isNetwork;
      onNext(operation, snapshot, fetchHasReturned && !onlyStore);

      if (onlyStore) {
        onComplete(null, fetchHasReturned);
      }
    } // Cancel any previously running refetch.


    _refetchSubscription && _refetchSubscription.unsubscribe();
    var refetchSubscription;

    if (isNetwork) {
      var resolveNetworkPromise_1 = function () {}; // Declare refetchSubscription before assigning it in .start(), since
      // synchronous completion may call callbacks .subscribe() returns.


      var cleanup_1 = function () {
        if (_refetchSubscription === refetchSubscription) {
          _refetchSubscription = null;
        }

        isLoading = false;
        promise = null;
      };

      var complete_1 = function (error) {
        if (error === void 0) {
          error = null;
        }

        resolveNetworkPromise_1();
        update(false, error);
        cleanup_1();
        onComplete(error, fetchHasReturned);
      };

      fetchQuery(environment, operation).subscribe({
        unsubscribe: function () {
          cleanup_1();
        },
        complete: complete_1,
        error: function (e) {
          return complete_1(e);
        },
        next: function (response) {
          var store = environment.lookup(operation.fragment);
          promise = null;
          var responses = Array.isArray(response) ? response : [response];
          var cacheConfig = operation.request.cacheConfig;
          var isQueryPolling = !!cacheConfig && !!cacheConfig.poll;
          var isIncremental = responses.some(function (x) {
            return x != null && x.hasNext === true;
          });
          isQueryPolling && update(false);
          resolveNetworkPromise_1();
          onResponse && onResponse(response);
          onNext(operation, store, fetchHasReturned && (isIncremental || isQueryPolling));
        },
        start: function (subscription) {
          refetchSubscription = subscription;
          _refetchSubscription = refetchSubscription;
          update(true);
        }
      });

      if (!snapshot) {
        promise = new Promise(function (resolve) {
          resolveNetworkPromise_1 = resolve;
        });
      }
    }

    fetchHasReturned = true;
    return {
      dispose: function () {
        refetchSubscription && refetchSubscription.unsubscribe();
      }
    };
  };

  var checkAndSuspense = function (suspense, useLazy) {
    clearTemporaryRetain();
    var toThrow = promise || error;

    if (suspense && toThrow) {
      if (promise && useLazy) {
        temporaryRetain();
      }

      throw toThrow;
    }

    return toThrow;
  };

  var getData = function () {
    return {
      isLoading: isLoading,
      error: error
    };
  };

  return {
    fetch: fetch,
    getData: getData,
    dispose: dispose,
    checkAndSuspense: checkAndSuspense
  };
}